import { Vue, Component, Prop } from 'vue-property-decorator';
import Loader from '@/components/common/loader/Loader.vue';
import Navbar from '@/components/common/navbar/Navbar.vue';
import FooterExpanded from '@/components/common/footer-expanded/FooterExpanded.vue';
import { titleTemplate } from '@/helpers/meta/title-template';
import { name } from '../../../../whitelabel.config';

@Component({
  components: {
    Loader,
    Navbar,
    FooterExpanded,
  },
})

export default class StaticErrors extends Vue {
  @Prop({ default: '' })
  errorType!: string;

  @Prop({ default: false })
  hideHeader!: string;

  metaInfo(): { title: string } {
    return {
      title: !this.hideHeader ? titleTemplate('Error') : name,
    };
  }

  get error(): string {
    return this.$route.params.errorType || this.errorType;
  }

  get defaultContent(): { [key: string]: string } {
    return {
      img: 'error-icon.svg',
      imgWidth: '60',
      title: this.$t('checkout.errors.somethingIsNotWorking').toString(),
      text: this.$t('checkout.errors.problemPersist').toString(),
      backButtonTo: '/knowledge/faq',
    };
  }

  get generatedContent(): { [key: string]: string } {
    const content = { ...this.defaultContent };

    Object.keys(this.defaultContent).forEach((key): void => {
      (this as any)[key] = (this.defaultContent as any)[key];
    });

    // @ts-ignore
    switch (this.error) {
      case '404':
        content.img = '404.svg';
        content.imgWidth = '300';
        content.title = this.$t('checkout.errors.somethingIsMissing').toString();
        content.text = this.$t('checkout.errors.error404').toString();

        break;
      case 'credentials':
      content.title = this.$t('checkout.errors.notEnoughPermissions').toString();

        break;
      case 'idin':
        content.img = 'error-icon.svg';
        content.imgWidth = '60';
        content.title = this.$t('checkout.errors.somethingWentWrong').toString();

        break;
      default:

        break;
    }

    return content;
  }

  getImgSrc(pic: string): NodeRequire {
    return require(`../../../assets/images/${pic}`);
  }
}
