import { Vue, Component, Watch } from 'vue-property-decorator';
import { State as StateClass, Action } from 'vuex-class';
import Loader from '@/components/common/loader/Loader.vue';
import Navbar from '@/components/common/navbar/Navbar.vue';
import FooterExpanded from '@/components/common/footer-expanded/FooterExpanded.vue';
import { State } from '@/store/models';
import { titleTemplate } from '@/helpers/meta/title-template';
import { BankAccountChange } from '@/store/models/user';

@Component({
  components: {
    Loader,
    Navbar,
    FooterExpanded,
  },
})

export default class Activate extends Vue {
  metaInfo(): { title: string } {
    return {
      title: titleTemplate(this.titleChunk),
    };
  }

  // Changes the page title dynamically
  get titleChunk(): string {
    return this.activateType === 'email' ? this.$tc('common.confirm') : this.$tc('common.activate');
  }

  title: string = '';
  text: string = '';
  backButtonTo: string = '';

  @StateClass bankAccountChanges!: State['bankAccountChanges'];
  @StateClass operations!: State['operations'];

  @Action confirmBankAccountChange!: Function;

  @Watch('bankAccountRequest')
  onBankAccountChanges(newBankAccountChanges: BankAccountChange, oldBankAccountChanges: BankAccountChange): void {
    if (this.requestId && this.activateType === 'email' && newBankAccountChanges && !oldBankAccountChanges) {
      this.generateContext();
      if (!newBankAccountChanges.changed) {
        this.confirmBankAccountChange(this.requestId);
      }
    }

    if (!newBankAccountChanges) {
      this.$router.replace('/error/404').catch((): void => {});
    }
  }

  mounted(): void {
    if (!this.activateType || (this.activateType === 'email' && !this.requestId)
      || (this.bankAccountChanges.length > 0 && !this.bankAccountRequest)) {
      this.$router.replace('/error/404').catch((): void => {});
      return;
    }

    if (this.bankAccountRequest && this.activateType === 'email') {
      this.generateContext();
      if (!this.bankAccountRequest.changed) {
        this.confirmBankAccountChange(this.requestId);
      }
    }
  }

  get requestId(): string | undefined {
    return this.$route.params.id;
  }

  get activateType(): string {
    return this.$route.params.activateType;
  }

  get loading(): boolean {
    return this.operations.status === 'processing';
  }

  get bankAccountRequest(): State['bankAccountChanges'][0] | undefined {
    return this.bankAccountChanges.find((bankAccountChange): boolean => bankAccountChange.id === this.requestId);
  }

  get success(): boolean {
    return this.operations.status === 'success' || (!!this.bankAccountRequest && this.activateType === 'email'
      && this.bankAccountRequest.changed);
  }

  generateContext(): void {
    switch (this.activateType) {
      case 'email':
        this.title = this.$t('common.success') as string;
        this.text = this.$t('settings.details.bankAccountChanged') as string;

        break;
      default:

        break;
    }
  }
}
